import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  createContext, 
  useContext,
  forwardRef,
  useLayoutEffect,
  Component
} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import { ArrowLeft } from '../../Icons/ArrowLeft';
import { ArrowRight } from '../../Icons/ArrowRight';
import { ArrowDown } from '../../Icons/ArrowDown';
// import linksTree from '../../../../linksTree';
// import

// const treeTest = [
//   {
//     item: 'item 1',
//     link: '/item1',
//     children: [
//       {
//         item: 'item 1_1',
//         link: '/item1/i1',
//       },
//       {
//         item: 'item 1_2',
//         link: '/item1/i2',
//         children: [
//           {
//             item: 'item 1_2_1',
//             link: '/item1/i2/i1',
//           },
//           {
//             item: 'item 1_2_2',
//             link: '/item1/i2/i2',
//           },
//         ],
//       },
//       {
//         item: 'item 1_3',
//         link: '/item1/i3',
//       },
//     ]
//   },
//   {
//     item: 'item 2',
//     link: '/item2',
//   }
// ];

// console.log('()()()()---');
// console.log(linksTree());

interface INode {
  item: string | JSX.Element;
  link: string;
  isActive?: boolean;
  children?: INode[];
}

export interface INavigationMenuProps {
  tree: INode[];
  trigger: string | JSX.Element;
  renderHidden?: boolean;
}

const RenderHiddenContext = createContext(null);
const RootShowPopupContext = createContext(null);
const TriggerRefContext = createContext(null);
const MobileStatesContext = createContext(null);

const EventProps = createContext(null);

const Trigger = styled.button`
  background-color: ${({theme}) => theme.themeColors.support[100]};
  color: ${({theme}) => theme.themeColors.support[500]};
  padding: 0;
  border-radius: 3px;
  border: none;
  &:focus.focus-visible {
    outline: 2px solid ${(props) => props.theme.palette.primary};
    outline-offset: 2px;
  }
`;

const TreeWrapper = styled.div`
  position: absolute;
`;

interface IListProps {
  isShown?: boolean;
  isMobile?: boolean;
  isMobileShown?: boolean;
  parentPosition?: {[k: string]: number}
}

const ListWrapper = styled.ul<IListProps>`
  position: absolute;
  //position: relative;
  ${props => props.parentPosition?.top ? `top: 0px;` : ''}
  ${props => props.parentPosition?.right ? `left: ${props.parentPosition?.width}px;` : ''}
  //overflow: hidden;
  list-style-type: none;
  margin: 0;
  padding: 0;
  // opacity: ${(props) => props.isShown ? '1' : '.5'};
  display: ${(props) => props.isShown ? 'block' : 'none'};
  background-color: rgba(255,255,255,1);
  // border: solid 1px ${({theme}) => theme.themeColors.support["200"]};
  border: ${(props) => props.isMobileShown ? `solid 1px ${props.theme.themeColors.support["200"]}` : 'none'};
  box-sizing: border-box;
`;

const StyledListItem = styled.li<{isHidden: boolean;}>`
  position: relative;
  ${(props) => props.isHidden ? 'display: none;' : ''}
`;

const StyledA = styled(Link)<{$isHidden: boolean}>`
  display: ${(props) => props.$isHidden ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
  padding: 6px;
  color: black;
  text-decoration: none;
  font-size: 14px;
  :hover {
    background-color: ${(props): string => props.theme.palette.hoverGray};
  }
  :focus.focus-visible {
    outline: none;
    box-shadow: 0 0 1px 2px ${({theme}) => theme.palette.primary} inset;
  }
`;

interface IMenuLink extends IWithChildren {
  to: string;
  isFocused: boolean;
  isHidden: boolean;
}

// const f = forwardRef()

const MenuLink = forwardRef<HTMLAnchorElement, IMenuLink>((
  {
    children,
    to,
    isFocused,
    // ...rest,
    isHidden,
  }, ref): JSX.Element => {

  const handlers = useContext(EventProps);
  const {
    onClick,
    onMouseOver,
    onKeyDown,
    onTouchStart,
    onTouchEnd,
  } = handlers;

  return (
    <StyledA
      role={'menuitem'}
      to={to}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onKeyDown={onKeyDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      ref={ref}
      tabIndex={isFocused ? 0 : -1}
      $isHidden={isHidden}
    >
      {children}
    </StyledA>
  )
});

interface IMenuItem extends INode {
  isShown: boolean;
  isMobileShown: boolean;
  isFocused: boolean;
  isExpanded: boolean;
  resetExpanded: () => void;
  eventType: string;
}

const StyledBackItem = styled.button`
  display: flex;
  border: none;
  background-color: ${({theme}) => theme.themeColors.support[100]};
  align-items: center;
  padding: 6px;
  width: 100%;
  :hover {
    background-color: ${(props): string => props.theme.palette.hoverGray};
  }
  :focus.focus-visible {
    outline: none;
    box-shadow: 0 0 1px 2px ${({theme}) => theme.palette.primary} inset;
  }
`;

const MobileBackItem: FC<{isShown: boolean; isMobileShown: boolean; parentResetExpanded: any}> = ({isShown, isMobileShown, parentResetExpanded}) => {
  const liRef = useRef(null); // todo remove?
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    parentResetExpanded();
    // console.log('click back item')
    /*
    1) hide current menu
    2) activate its parent
     */
  }, []);

  // console.log('-=-=-= &&');
  // console.log({isShown, isMobileShown});
  return (
    <StyledListItem
      role="none"
      ref={liRef}
      isHidden={!isShown || !isMobileShown}
      // isHidden={!isMobileShown}
    >
      <StyledBackItem
        onClick={handleClick}
      >
        <ArrowLeft />
        <span>{t('Back')}</span>
      </StyledBackItem>
    </StyledListItem>
  )
};

const MenuItem: FC<IMenuItem> = (
  {
    item,
    children,
    isActive,
    link,
    isFocused,
    isExpanded,
    isShown,
    isMobileShown,
    resetExpanded,
    eventType
  }) => {

  // console.log('MenuItem ---- ');
  // console.log({item, isMobileShown, isExpanded});

  const renderHidden = useContext(RenderHiddenContext);

  useEffect(() => {
    if (isFocused && isShown) {
      aRef?.current?.focus();
    }
  }, [isFocused, isShown]);

  const liRef = useRef(null);
  const aRef = useRef(null);

  const [position, setPosition] = useState({});

  typeof window === 'object' && useLayoutEffect(() => {
    setPosition(aRef.current.getBoundingClientRect());
  }, [isExpanded]);

  // console.log('menu link is hidden----');
  // console.log(item)
  // console.log(!isMobileShown && isExpanded);

  return (
    <StyledListItem
      role="none"
      ref={liRef}
      isHidden={!isMobileShown && !isExpanded}
    >
      <MenuLink
        to={link}
        ref={aRef}
        isFocused={isFocused}
        isHidden={!isMobileShown && isExpanded}
      >
        <span>{item}</span>
        {children && (isExpanded ? <ArrowDown /> : <ArrowRight />)}
      </MenuLink>
      {children && (isExpanded || renderHidden) && (<TreeLevel
        tree={children}
        isShown={isExpanded}
        parentPosition={position}
        trigger={aRef}
        parentResetExpanded={resetExpanded}
        parentEventType={eventType}
      />)}
    </StyledListItem>
  );
};

const TreeLevel = ({ tree, isShown = false, parentPosition = {}, trigger, parentResetExpanded = () => {}, parentEventType = '' }) => {
  const [focusedItem, setFocusedItem] = useState(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [eventType, setEventType] = useState('');

  const rootSetShowPopup = useContext(RootShowPopupContext);
  const rootTriggerRef = useContext(TriggerRefContext);
  const mobileStates = useContext(MobileStatesContext);
  const { isMobile } = mobileStates;

  // useEffect(() => {
  //   console.log({expandedItem});
  //   console.log(expandedItem !== null ? tree[expandedItem].children : undefined)
  // }, [expandedItem]);

  // const isLastShownLeaf = useCallback(() => {
  //   console.log('isLastShownLeaf-=-=-=');
  //   return !(expandedItem !== null || tree[expandedItem]?.children !== undefined);
  // }, [expandedItem]);
  //
  // const isShownMobile = useCallback(() => {
  //   console.log('isShownMobile ------');
  //   console.log({isShown, isMobile, isLastShownLeaf: isLastShownLeaf()});
  //   console.log((!isMobile || (isMobile && isLastShownLeaf())));
  //   console.log(isShown && (!isMobile || (isMobile && isLastShownLeaf())));
  //   return isShown && (!isMobile || (isMobile && isLastShownLeaf()));
  // }, [isShown, isMobile, expandedItem]);

  useEffect(() => {
    if (isShown) {
      if (parentEventType === 'keyboard') {
        setFocusedItem(0);
      }
    } else {
      setFocusedItem(null);
      setExpandedItem(null);
    }
  }, [isShown, parentEventType]);

  const handleMouseOver = useCallback((index) => {
    setEventType('mouse');
    setFocusedItem(index);
    setExpandedItem(index);
  }, []);

  const resetExpanded = useCallback(() => {
    // console.log('resetExpanded -=-=-=-');
    // console.log({expandedItem, focusedItem, eventType});
    setExpandedItem(null);
  }, []);

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    rootSetShowPopup(false);
  }, []);

  const handleTouchStart = useCallback((e, index, children) => {
    // console.log('handleTouchStart ----');
    // console.log(e);

  }, []);

  const handleTouchEnd = useCallback((e, index, children) => {
    // console.log('handleTouchEnd ----');
    // console.log({e, index, children});

    if (children) {
      e.stopPropagation();
      e.preventDefault();
      setFocusedItem(index);
      setExpandedItem(index);
    }
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if(!/^ArrowRight$|^ArrowLeft$|^ArrowDown$|^ArrowUp$|^Escape$|^Enter$/.test(e.key)) {
      return;
    }
    if (e.key !== 'Enter') {
      e.preventDefault();
    }
    e.stopPropagation();

    switch (e.key) {
      case 'ArrowDown':
        setFocusedItem(focusedItem === tree.length - 1 ? 0 : focusedItem + 1);
        break;

      case 'ArrowUp':
        setFocusedItem(focusedItem === 0 ? tree.length - 1 : focusedItem - 1);
        break;

      case 'ArrowRight':
        setEventType('keyboard');
        setExpandedItem(focusedItem);
        break;

      case 'Escape':
      case 'ArrowLeft':
        trigger.current.focus();
        parentResetExpanded();
        break;

      case 'Enter':
        rootSetShowPopup(false);
        setTimeout(() => {
          rootTriggerRef?.current.focus();
        }, 0)
    }
  }, [focusedItem]);

  const isMobileShown = () => {
    // console.log('******----');
    // console.log(JSON.stringify(tree));
    // console.log(expandedItem === null || !tree[expandedItem]?.children?.length);
    // console.log({isMobile, isLeaf: expandedItem === null || !tree[expandedItem]?.children?.length})
    return isShown && (!isMobile || (expandedItem === null || !tree[expandedItem]?.children?.length))
  }

  // console.log('is really shown -=-=-=-=');
  // console.log(JSON.stringify(tree));
  // console.log(isReallyShown());

  // console.log('_+_+_+_+');
  // console.log({isMobile});

  return (
    <ListWrapper
      isShown={isShown}
      isMobileShown={isMobileShown()}
      // isShown={isReallyShown()}
      // isShown={isShownMobile()}
      parentPosition={parentPosition}
    >
      <MobileBackItem
        isShown={isMobile}
        isMobileShown={isMobileShown()}
        parentResetExpanded={parentResetExpanded}
      />
      {tree && tree.map((
        {
          item,
          link,
          children,
          isActive
        }, index) => {
        return (
          <EventProps.Provider
            key={index}
            value={{
              onClick: handleClick,
              onMouseOver: () => handleMouseOver(index),
              onKeyDown: handleKeyDown,
              onTouchStart: (e) => handleTouchStart(e, index, children),
              onTouchEnd: (e) => handleTouchEnd(e, index, children),
            }}>
            <MenuItem
              item={item}
              link={link}
              {...(children && { children })}
              {...(isActive && { isActive })}
              isFocused={index === focusedItem}
              isExpanded={index === expandedItem}
              isShown={isShown}
              isMobileShown={isMobileShown()}
              eventType={eventType}
              resetExpanded={resetExpanded}
            />
          </EventProps.Provider>
        )
      })}
    </ListWrapper>

  )
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const NavigationTree: FC<INavigationMenuProps> = (
  {
    tree,
    renderHidden= true,
    trigger,
  }
) => {

  // const { i18n } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);
  const [eventType, setEventType] = useState('');
  const [isMobile, setIsMobile] = useState(typeof navigator !== 'undefined' && navigator.maxTouchPoints > 0);
  const [orientation, setOrientation] = useState(typeof window !== 'undefined' && window.screen?.orientation?.angle);

  const triggerRef = useRef(null);
  const wrapper = useRef(null);
  // const treeRef = useRef(null);

  // useEffect(() => {
  //   treeRef.current = linksTree(i18n);
  //   console.log('get tree ---===---');
  //   console.log(treeRef.current);
  // }, [i18n.language]);

  const clickTrigger = useCallback((e) => {
    // console.log('clickTrigger -=-=-=');
    setEventType('mouse');
    setShowPopup(!showPopup);
  }, [showPopup]);

  const keyDownTrigger = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    // console.log(e.key);
    if (!/^ArrowDown$|^Enter$|^ $/.test(e.key)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setEventType('keyboard');
    setShowPopup(true);

  }, []);

  const handleClickOutside = useCallback((e: MouseEvent): void => {
    const domNode = wrapper?.current;
    const target = e.target as HTMLElement;

    if (!domNode?.contains(target)) {
      setShowPopup(false);
    }
  }, []);

  const resetExpanded = useCallback(() => {
    setShowPopup(false);
  }, []);

  const windowResize = useCallback(() => {
    // console.log('resize window ---');
    setIsMobile(typeof navigator !== 'undefined' && navigator.maxTouchPoints > 0);
  }, []);

  const orientationChange = useCallback(() => {
    setOrientation(typeof window !== 'undefined' && window.screen?.orientation?.angle);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    // console.log('use effect ---');
    // console.log(window);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', windowResize);
      window.screen?.orientation.addEventListener('change', orientationChange);
    }
    typeof window !== 'undefined' && window.addEventListener('resize', windowResize)
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', windowResize)
        window.screen?.orientation.removeEventListener('change', orientationChange);
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log({orientation, isMobile});
  // }, [orientation, isMobile]);

  return (
    <RenderHiddenContext.Provider value={renderHidden}>
      <RootShowPopupContext.Provider value={setShowPopup}>
        <TriggerRefContext.Provider value={triggerRef}>
          <MobileStatesContext.Provider value={{
            isMobile,
            orientation,
          }}>
            <Wrapper
              ref={wrapper}
            >
              <Trigger
                onClick={clickTrigger}
                onKeyDown={keyDownTrigger}
                // aria-controls={popupId}
                // aria-haspopup
                // aria-expanded={showPopup}
                ref={triggerRef}
              >
                {trigger}
              </Trigger>
              {(showPopup || renderHidden) && (
                <TreeWrapper>
                  <TreeLevel
                    tree={tree}
                    isShown={showPopup}
                    trigger={triggerRef}
                    parentEventType={eventType}
                    parentResetExpanded={resetExpanded}
                  />
                </TreeWrapper>
              )}
            </Wrapper>
          </MobileStatesContext.Provider>
        </TriggerRefContext.Provider>
      </RootShowPopupContext.Provider>
    </RenderHiddenContext.Provider>
  )
};
